.hero {
  position: relative;
  background: black;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 400px;
}

.logo {
  display: none;
  position: absolute;
  top: -25%;
  right: 0%;
  height: 150%;
  z-index: 1;
}

.content {
  position: absolute;
  z-index: 2;
  //background:rgba(255,20,147, 0.9);
  color: white;
  width: 100%;
  text-align: left;
  padding: 0 3rem;
}

.title {

}

.brand {
  position: relative;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: 800;
  font-size: 6rem;
  //border-bottom: 10px solid white;
  padding: 1rem 1.25rem 1rem 0;
  line-height: 1;
  color: white;
  z-index: 2;
  text-shadow: 0 0 100px rgba(0,0,0,0.5);
}

.slogan {
  font-size: 2rem;
  padding-left: 1rem;
}

.video {
  opacity: 0.9;
  z-index: 1;
}


@media (min-width: 768px) {
  .hero {
    max-height: 600px;
  }
}

@media (min-width: 1440px) {
  .logo {
    display: block;
  }
}
