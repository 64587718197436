html {
  font-size: 8px;
}

body {
  background: black;
  color: white;
  font-size: 8px;
}

h1, h2, h3, .accent-font {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

p {
  font-size: 1rem;
}


.font-weight-800 {
  font-weight: 800;
}


@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
