.signupForm {
  background: white;
  color: black;
  border-radius: 1rem;
  padding: 2.5rem 2rem;
  box-shadow: 0 10px 30px rgb(0 0 0 / 60%);

  label {
    color: black;
    font-weight: 800;
    font-size: 1rem;
  }
  .form-check {
    padding-left: 1.5rem;
    label {
      font-weight: normal;
    }
    input {
      font-size: 1rem;
    }
  }

}
