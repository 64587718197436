.banner--below-nav {
  background: deeppink;
  padding: 0.5rem;
  box-shadow: 0 10px 20px black;
  p {
    font-size: 1.5rem;
  }
}


@media (min-width: 768px) {
  .banner--below-nav {
    p {
      font-size: 0.85rem;
    }
  }
}
