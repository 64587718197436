#what-lushlink-can-do {
  .card {
    text-align: center;
    margin-bottom: 3rem;
    padding: 0 2rem;
    .card-img-top {
      height: 150px;
      margin-bottom: 20px;
    }
    .card-title {
      font-size: 3rem;
    }
    .card-text {
      font-size: 2rem;
    }
    .card-footer {
      a {
        font-size: 2rem;
      }
    }
  }
}


@media (min-width: 768px) {
  #what-lushlink-can-do {
    .card {
      text-align: center;
      margin-bottom: 0;
      padding: 0;
      .card-title {
        font-size: 1.25rem;
      }
      .card-text {
        font-size: 0.85rem;
      }
      .card-footer {
        a {
          font-size: 1rem;
        }
      }
    }
  }
}
