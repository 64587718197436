/* The following block can be included in a custom.scss */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,800;1,400;1,800&display=swap');


/* make the customizations */
@import "variables";

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

