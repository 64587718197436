.navbar {
  color: white;
}

.navbar-brand {
  font-size: 2.5rem;
}

.navbar-nav {
  a {
    font-size: 1rem;
  }
}

.logo {
  max-width: 25px;
  margin: -5px 5px 0 0;
}


@media (min-width: 768px) {
  .navbar-brand {
    font-size: 1.5rem;
  }
}
