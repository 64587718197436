.footer {
  border-top: 1rem solid greenyellow;
  .row:first-child {
    padding: 5rem 0;
  }

  h1 {
    line-height: 1;
    .logo {
      max-width: 75px;
      margin: 0 1rem 0 0 ;
    }
  }

}
.credit {
  background: #1c1c1c;
  padding: 1rem 0;
}
